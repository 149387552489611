import React from "react";
import styled from "styled-components";

import {useState, useEffect, useContext} from 'react'

import {MainClaimContext} from '../ClaimContext'

import Footer from './Footer'

import ClaimRewards from './ClaimRewards'

import stake_full_info from './updated_stake_full_info.json'


const CLAIM_SCRIPT_ADDRESS = 'addr1q8ndnm44cy9zy2k6u2p027tsm9tqr4gavvjxg8yeat6gn09sxakaz3clkwewq90rpgv5r350d67pheuvd4a5slfdchqq8qa2e4'



const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: radial-gradient(circle at top, #1e1e42, #0f172a, #050c1e);
  color: #ffffff;
  padding: 20px;
  text-align: center;
  padding-top: 120px;
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 1s ease-in-out;
  
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: rgba(15, 23, 42, 0.85);
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
`;

const Logo = styled.h1`
  font-size: 2.5rem;
  font-weight: 900;
  color: #60a5fa;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const WalletButton = styled.button`
  background: linear-gradient(90deg, #7b61ff, #d95dff);
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 12px 30px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 4px 15px rgba(123, 97, 255, 0.5);

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 20px rgba(123, 97, 255, 0.8);
  }
`;

const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: linear-gradient(90deg, #60a5fa, #9333ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 1.2s ease-in-out;
`;

const Subtitle = styled.p`
  font-size: 1.8rem;
  max-width: 750px;
  margin-bottom: 30px;
  opacity: 0.85;
  animation: fadeIn 1.4s ease-in-out;
`;

const VideoContainer = styled.div`
  width: 85%;
  max-width: 900px;
  background: rgba(30, 41, 59, 0.8);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.7);
`;

const Details = styled.p`
  font-size: 1.8rem;
  font-weight: 700;
  color: #60a5fa;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
`;

const PurchaseButton = styled.button`
  background: linear-gradient(45deg, #2563eb, #9333ea);
  color: #fff;
  font-size: 2.2rem;
  font-weight: 900;
  padding: 22px 70px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 5px 30px rgba(59, 130, 246, 0.5);
  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 7px 35px rgba(59, 130, 246, 0.7);
  }
  margin-bottom: 50px;
`;

const NFTGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const NFTCard = styled.div`
  background: rgba(30, 41, 59, 0.9);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  width: 320px;
  height: 320px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;

const NFTImage = styled.img`
  width: 100%;
  height: 320px;
  object-fit: cover;

  border-radius: 20px;
`;


const InfoContainer = styled.div`
  background: rgba(45, 55, 72, 0.95);
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  max-width: 900px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  margin-top: 20px;
`;

const InfoText = styled.p`
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  opacity: 0.85;
  margin: 5px 0;
`;


const NFTPresale = (props) => {

  const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, isVoteOpen, voteOption, voteProposal, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, setIsVoteOpen, setVoteOption, setVoteProposal} = useContext(MainClaimContext)
  
  const [isHolder, setIsHolder] = useState(false)

  const {setCurrentStep} = props

  const {saleOver} = props


  useEffect(() => {

    let temp_is_holder = false

    // console.log('\n\n\n')
    // console.log(stake_full_info.hasOwnProperty(stake_address))
    // console.log('\n\n\n')

    
      tokens.map((token) => {
        //console.log(token)

        let cur_unit = token['unit']

        if (cur_unit.slice(0,56) === '40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728'){
         
          temp_is_holder = true

        }

        else if (cur_unit.slice(0,56) === '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768'){
         
          temp_is_holder = true

        }

        else if (cur_unit.slice(0,56) === '9e59c2dea85a6e6b70269d838c923b78badc868b13432abc0b0ba107'){
         
          temp_is_holder = true

        }

        else if (cur_unit.slice(0,56) === '13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e'){

          temp_is_holder = true

        }

      })



      if (stake_full_info.hasOwnProperty(stake_address)){
        temp_is_holder = true
      }

    
      setIsHolder(temp_is_holder)
   

  },[tokens, stake_address])




  return (
    <GlobalContainer>
      {/*<NavBar>
        <Logo>MyNFT</Logo>
        <WalletButton>Connect Wallet</WalletButton>
      </NavBar>*/}
      <Title>Custom CLAY Order</Title>
      <Subtitle>Now’s your chance to order your own custom clay NFT and become a part of this exclusive collection.</Subtitle>
      <VideoContainer>
        <video width="100%" height="auto" loop autoPlay controls muted>
          <source src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/custom_nfts_examples/Custom%20Clay%20Trailer.mp4" type="video/mp4" />
        </video>
      </VideoContainer>

      
      {/*<PurchaseButton>Pre-Order Now</PurchaseButton>*/}


      {saleOver? 

      <div>
        <Details>It is not possible to place new orders. <br />Connect your wallet to track your placed order.</Details>
      </div>
      
      :

      <>
      <Details>Only 50 available | Price: 777 ADA <br /> Clay Holder Price: 500 ADA</Details>
      <ClaimRewards isHolder={isHolder} walletConnected={true} setClaimStake={()=>{}} CLAIM_SCRIPT_ADDRESS={CLAIM_SCRIPT_ADDRESS} setCurrentStep={setCurrentStep}> </ClaimRewards>
      </>
    }

      

      <div style={{height:'60px'}}>

      </div>


      <InfoContainer>
        <InfoText>* Due to popular demand, it is now possible to order your very own custom clay in this first batch of 50. *</InfoText>
        <InfoText>It can be based on you, a friend, a family member - or even a pet.</InfoText>
        <InfoText>Once complete, your custom clay will be sent directly to the wallet you order with.</InfoText>
        <InfoText>Please note, orders will be done in the order they are received.</InfoText>
        <InfoText>Please be prepared to expect long waiting times if you are later in line.</InfoText>
        <InfoText>We have just one Joe the clay with the clay hands who will be making these to order.</InfoText>
        <InfoText>Any questions, please open a ticket in Discord and we will come back to you as soon as we can.</InfoText>
        <InfoText>Pricing has been based on a number of factors, including direct feedback from community members - but most importantly reflects our brand IP and the craftsmanship for each creation.</InfoText>
        <InfoText>Each order is fixed at 500 ADA for clay holders and 777 ADA for non-holders - future add-on options to animate or request a 3D model will be possible in the next few months.</InfoText>
        <InfoText>We aim to be inclusive while also considering the existing collection and floor/past sales.</InfoText>
        <InfoText>Remember you can alternatively find a clay you connect with in our existing collections :)</InfoText>
      </InfoContainer>


      <div style={{height:'60px'}}>

      </div>


      <div style={{height:'60px'}}>

      </div>


      <NFTGallery>
        {[2,3,4,5].map(id => (
          <NFTCard key={id}>
            <NFTImage src={`https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/custom_nfts_examples/Custom%20${id}.png`} alt="Custom NFT" />
          </NFTCard>
        ))}
      </NFTGallery>



      <div style={{position:'relative', marginTop:'60px', marginBottom:'150px', width:'100%', 
                                 height:'200px', backgroundColor:'', display:'flex',
                                 justifyContent:'center', alignItems:'center', marginLeft:'0px'
                                }}>
        <div style={{width:'65%', minWidth:'310px', marginTop:'20px'}}>
          <Footer />
        </div>
      </div>



    </GlobalContainer>
  );
};

export default NFTPresale;
