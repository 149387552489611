import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import Footer from './Footer'

import ClaimRewards from './ClaimRewards'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 24px;
  color: white;
  background: linear-gradient(to bottom right, black, #1a1a1a, black);
  position: relative;
  overflow: hidden;
`;

const BackgroundEffect = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 400px;
    height: 400px;
    filter: blur(120px);
    border-radius: 50%;
  }
  &::before {
    background: rgba(0, 128, 255, 0.2);
    top: -100px;
    left: -100px;
  }
  &::after {
    background: rgba(128, 0, 255, 0.2);
    bottom: -100px;
    right: -100px;
  }
`;

const Card = styled(motion.div)`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s ease-out;
  background: #222;
  padding: 8px;
  ${({ selected }) =>
    selected
      ? css`
          box-shadow: 0 0 25px rgba(0, 128, 255, 0.8);
          border: 4px solid rgba(0, 128, 255, 0.8);
          transform: scale(1.05);
        `
      : css`
          &:hover {
            transform: scale(1.03);
            box-shadow: 0 0 20px rgba(0, 128, 255, 0.6);
          }
        `}
`;

const WalletButton = styled(motion.button)`
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 600;
  background: #222;
  color: white;
  border: 2px solid #444;
  border-radius: 999px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: #333;
    border-color: white;
    transform: scale(1.05);
  }
`;

const VoteButton = styled(motion.button)`
  margin-top: 48px;
  padding: 16px 32px;
  font-size: 24px;
  font-weight: 700;
  background: linear-gradient(to right, #0066ff, #9900ff);
  color: white;
  border: none;
  border-radius: 999px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease-in-out;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
  &:hover {
    transform: ${({ disabled }) => (disabled ? "scale(1)" : "scale(1.05)")};
    box-shadow: ${({ disabled }) => (disabled ? "none" : "0 0 15px rgba(0, 128, 255, 0.6)")};
  }
`;


const NFTGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const NFTCard = styled.div`
  background: #222;
  backdrop-filter: blur(8px);
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  width: 220px;
  height: 220px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;

const NFTImage = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;

  border-radius: 20px;
`;


const SwapBox = styled.div`
  background: #222;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 0 20px rgba(0, 128, 255, 0.5);
  width: 360px;
  text-align: center;
`;

const SwapButton = styled(motion.button)`
  margin-top: 16px;
  padding: 16px 32px;
  font-size: 20px;
  font-weight: 700;
  background: linear-gradient(to right, #0066ff, #9900ff);
  color: white;
  border: none;
  border-radius: 999px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 128, 255, 0.6);
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const InfoBox = styled.div`
  margin-top: 16px;
  padding: 12px;
  font-size: 16px;
  color: #ccc;
  background: #111;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #444;
`;

export default function VotingPage(props) {

  const {matchesM, isPopUpOpen, stake_address, snayke_balance, set_snayke_balance} = props

  const [selected, setSelected] = useState(null);
  const [voted, setVoted] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [totalVotes, setTotalVotes] = useState(0);

  const [displayedVotes, setDisplayedVotes] = useState('')
  
  const images = [
    { id: 1, src: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Clay_Memes/1.png", alt: "", name: 'Syth' },
    { id: 2, src: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Clay_Memes/4.png", alt: "", name: 'Rookie' }
  ];




  const [balance, setBalance] = useState(""); // Example balance
  const [swapping, setSwapping] = useState(false);

  

  useEffect(() => {


    let vote_balance = snayke_balance 
    
    if (vote_balance < 1000){
      vote_balance = (parseInt(totalVotes)).toFixed(2);
    }else if(vote_balance < 1000000){
      vote_balance = (parseInt(vote_balance) / 1000).toFixed(2) + 'K';
    }else{
      vote_balance = (parseInt(vote_balance) / 1000000).toFixed(2) + 'M';
    }


    setBalance(vote_balance)

  },[snayke_balance])


  async function submitVote(stake_address, snayke_balance, selected){

    let submit_url = 'https://claymarkets.com/api/voting/vote/'

    const data = {stake_address:stake_address, amount: snayke_balance, selected: selected}

    const response = await fetch(submit_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })



    return response.json()

}



async function getVoteInfo(stake_address){

  let temp_stake_address = stake_address
  if (stake_address == ''){
    temp_stake_address = 'test'
  }

  let query_url = 'https://claymarkets.com/api/voting/info/' + temp_stake_address +'/'

  let current_step = await fetch(query_url).then(response => response.json())
                                                                  .then(data => data)



   // console.log('\n\n\n') 
   
   // console.log(current_step)  

   // console.log('\n\n\n')                                                            

  try{
    let temp_voted = current_step.voted
    if (temp_voted == 'y'){
      setVoted(true)
    }else{
      setVoted(false)
    }
  }catch{

  }


  try{
    let temp_voted = current_step.totalVotes
    setTotalVotes(parseFloat(temp_voted))
  }catch{
    
  }


  try{
    let temp_selected = current_step.selected

    if (parseInt(temp_selected) !== 0){
      setSelected(temp_selected)
    }

  }catch{
    
  }
 

  //console.log(temp_current_step)

  //return temp_current_step



}


// useEffect(() => {

//   getVoteInfo(stake_address);

//   const interval = setInterval(() => {
//     getVoteInfo(stake_address);
//   }, 5000);

//   return () => clearInterval(interval);

// }, [stake_address]);





  const handleVote = () => {
    if (selected && !voted) {
      setVoted(true);
      setTotalVotes(totalVotes + snayke_balance);

      submitVote(stake_address, snayke_balance, selected)

    }
  };


  useEffect(() => {

    let vote_balance = totalVotes 
    
    if (vote_balance < 1000){
      vote_balance = (parseInt(totalVotes)).toFixed(2);
    }else if(vote_balance < 1000000){
      vote_balance = (parseInt(vote_balance) / 1000).toFixed(2) + 'K';
    }else{
      vote_balance = (parseInt(vote_balance) / 1000000).toFixed(2) + 'M';
    }

    setDisplayedVotes(vote_balance)

  },[totalVotes])

  return (
    <Container>
      <BackgroundEffect />

      
     {/* <WalletButton onClick={() => setWalletConnected(true)}>
        {walletConnected ? "Wallet Connected" : "Connect Wallet"}
      </WalletButton>*/}

      <div style={{marginTop:'200px'}}>

      </div>



      {!isPopUpOpen?

      <>
        <h1 className="text-6xl font-extrabold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400" style={{fontSize: matchesM? '28px' : '18px'}}>
          Swap $snayke for $rookie
        </h1>


        <div style={{height:'100px'}}>

        </div>

        


      <SwapBox>
        <h2 className="text-2xl font-bold mb-4">Swap Tokens</h2>
        <p className="text-lg font-medium mb-4">Your Balance: {stake_address != '' ? balance : "Connect Wallet"}</p>
        


        <ClaimRewards stake_address={stake_address} snayke_balance={snayke_balance} swapping={swapping} setSwapping={setSwapping} set_snayke_balance={set_snayke_balance} />

        {/*<SwapButton onClick={handleSwap} disabled={stake_address == '' || snayke_balance == 0}>
          {swapping ? "Swapping..." : "Swap"}
        </SwapButton>*/}
        



        <InfoBox>
          Swap phase has ended.
        </InfoBox>
      </SwapBox>


       <div style={{height:'100px'}}>

        </div>


      <div style={{position:'relative', marginTop:'60px', marginBottom:'150px', width:'100%', 
                                 height:'200px', backgroundColor:'', display:'flex',
                                 justifyContent:'center', alignItems:'center', marginLeft:'0px'
                                }}>
        <div style={{width:'65%', minWidth:'310px', marginTop:'20px'}}>
          <Footer />
        </div>
      </div>



      </>

      : null}

    </Container>
  );
}
