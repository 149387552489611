import Button from '@mui/material/Button';
import { useState, useEffect, useContext} from 'react'
import styled from 'styled-components';
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'
import CircularProgress from '@mui/material/CircularProgress';

import { motion } from "framer-motion";

import Grid from '@mui/material/Grid'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ClaimClay.css'



import {MainClaimContext} from 'components/ClaimContext'

//import {MainClayPayContext} from '../../Context/ClayPayContext'

import ConnectHandler from './ConnectHandler'

import CustomCircularProgress from './CustomCircularProgress';

import cn_meta from './json/cn_db.json'

import trousers_info from './json/trousers_info.json'

import './Balance.css'



const theme = {
  anim: 'animate 7s linear infinite',
  op: 0.9
}

const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'

const PopUp = styled.div`
  background:  var(--background);
  border-radius: 32px;
  width: 40%;
  height: 60%;
  padding: 22px;
  position: fixed;
  left:28%;
  top: 22%;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  display: flex;
  z-index: 10;
  color: white;
  backdrop-filter: blur(20px);
  -webkit-animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`


const ConnectContainer = styled.div`

  display: flex;
  justify-content: center; 
  align-items: center;

  @media screen and (max-width: 680px) {
     width: 140px;
     height: 60px;   
  }
  @media screen and (min-width: 680px) {
    width: 180px;
    height: 80px; 
  }
`


const ConnectButton = styled.button`
  background: linear-gradient(90deg, #7b61ff, #d95dff);
  box-shadow: 0px 0px 15px rgba(123, 97, 255, 0.7);
  width: 100%; 
  height: 100%;
  border-radius: 13px;
  outline: 2px solid #7b61ff;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  text-align: center;
  color: white;
  padding-top: 5px;
  text-shadow: 2px 2px 0px black, 0px 0px 10px rgba(123, 97, 255, 0.7);
  cursor: pointer;
  z-index: 10;
  letter-spacing: 0.1rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: linear-gradient(90deg, #6a4df5, #c24be8);
    box-shadow: 0px 0px 25px rgba(123, 97, 255, 1);
    transform: scale(1.05);
  }

  @media screen and (max-width: 680px) {
    max-width: 125px;
    max-height: 55px; 
    font-size: 1.3rem;
  }
  @media screen and (min-width: 680px) {
    max-width: 600px;
    max-height: 65px;
    font-size: 1.5rem;
  }
`;



const WalletButton = styled(motion.button)`
  position: absolute;
  top: 24px;
  right: 44px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 600;
  background: #222;
  color: white;
  border: 2px solid #444;
  border-radius: 999px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  min-width: 150px;
  min-height: 60px;

  &:hover {
    background: #333;
    border-color: white;
    transform: scale(1.05);
  }
`;


export function DisplayAccount(props){

  const standardStyle = {
    width:'25px',
    height:'auto', 
    zIndex:'4', 
    marginLeft:'0px',
    marginRight:'10px'
  }

 const walletLogoOffsets = {

    'test':{
    'nami':{
      marginLeft: '-35px',
      marginRight:'30px'
    },
    'eternl':{
      marginLeft: '-45px',
      marginRight:'35px'
    },
    'yoroi':{
      marginLeft: '-45px',
      marginRight:'35px'
    },
    'flint':{
      marginLeft: '-55px',
      marginRight:'35px'
    },
    'gerowallet':{
      marginLeft: '-55px',
      marginRight:'35px'
    },
    'typhoncip30':{
      marginLeft: '-55px',
      marginRight:'35px'
    },
    'vespr':{
      marginLeft: '-55px',
      marginRight:'35px'
    }
  }
  }


  let cur_lovelace = props.lovelace
  let tokens = props.tokens

  let activeWallet = props.activeWallet
  let walletLogos = props.walletLogos

  let snayke_balance = props.snayke_balance
  let set_snayke_balance = props.set_snayke_balance


  const [clayBalance, setClayBalance] = useState('-')


  if (activeWallet in walletLogoOffsets){
    standardStyle.marginLeft = walletLogoOffsets[activeWallet].marginLeft
    standardStyle.marginRight = walletLogoOffsets[activeWallet].marginRight
  }

  cur_lovelace = parseInt(cur_lovelace, 10)

  let ada_balance = cur_lovelace / 1000000
  
  if (ada_balance < 1000){
    ada_balance = (parseInt(cur_lovelace) / 1000000).toFixed(2);
  }else if(ada_balance < 1000000){
    ada_balance = (parseInt(cur_lovelace) / 1000000000).toFixed(2) + 'K';
  }else{
    ada_balance = (parseInt(cur_lovelace) / 1000000000000).toFixed(2) + 'M';
  }


  function updateClayBalance(tokens){

    let cur_clay_dust = 0

    for (let i = 0; i < tokens.length; i++){
      let token_obj = tokens[i]

      let cur_unit = token_obj['unit']
      let cur_quantity = token_obj['quantity']



      // if (cur_unit === '38ad9dc3aec6a2f38e220142b9aa6ade63ebe71f65e7cc2b7d8a8535434c4159'){
      //   cur_clay_dust += parseInt(cur_quantity,10)
      // }

      if (cur_unit === 'bf2cea597ca605e7bbc0001c25a5bd115ddcc6dea6180ec91ebb1315436c617920536e616b65'){
        set_snayke_balance(parseFloat(cur_quantity))

        cur_clay_dust += parseFloat(cur_quantity)

      }

    }


    let ada_balance = cur_clay_dust

    set_snayke_balance(cur_clay_dust) 
  
   


  }





  function updateAdaBalance(){

     cur_lovelace = parseInt(cur_lovelace, 10)

  let ada_balance = cur_lovelace / 1000000
  
  if (ada_balance < 1000){
    ada_balance = (parseInt(cur_lovelace) / 1000000).toFixed(2);
  }else if(ada_balance < 1000000){
    ada_balance = (parseInt(cur_lovelace) / 1000000000).toFixed(2) + 'K';
  }else{
    ada_balance = (parseInt(cur_lovelace) / 1000000000000).toFixed(2) + 'M';
  }


    setClayBalance(ada_balance)


  }


  useEffect(() => {

     let ada_balance = snayke_balance
     let cur_clay_dust = snayke_balance

    if (ada_balance < 1000){
      ada_balance = (parseInt(cur_clay_dust)).toFixed(2);
    }else if(ada_balance < 1000000){
      ada_balance = (parseInt(cur_clay_dust) / 1000).toFixed(2) + 'K';
    }else{
      ada_balance = (parseInt(cur_clay_dust) / 1000000).toFixed(2) + 'M';
    }


    setClayBalance(ada_balance)

  },[snayke_balance])


  useEffect(() => {

    updateClayBalance(tokens)

  },[tokens])





  let walletLogo = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/' + walletLogos[activeWallet]

  return(
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
      <img src={walletLogo} style={standardStyle} /> 
      {clayBalance}
    </div>
  )
}




function Connect(props) {

   const {walletToEnable, setWalletToEnable,isPopUpOpen, setIsPopUpOpen, isBusy, setIsBusy, updateWallet, setUpdateWallet, set_stake_address, snayke_balance, set_snayke_balance} = props

   const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)



   const [cur_tokens, set_cur_tokens] = useState([])



   useEffect(()=>{

    set_cur_tokens(tokens)

   },[tokens])




   const Toggle = () => setIsPopUpOpen(!isPopUpOpen);

    const walletLogos = {
    nami:'nami.svg',
    eternl:'eternl.png',
    yoroi:'yoroi.svg',
    flint:'flint.svg',
    gerowallet:'gerowallet.svg',
    lace: 'lace.svg',
    typhoncip30: 'typhon.svg',
    vespr: 'vespr.png'
  }


  useEffect(() => {

    // let test_stake_address = ''
    // set_stake_address(test_stake_address)

    set_stake_address(stake_address)
  
  },[stake_address])


  return (
     <>

      <ConnectContainer>

        <ConnectHandler 
          isBusy={isBusy}
          setIsBusy={setIsBusy}
          updateWallet={updateWallet}
          setUpdateWallet={setUpdateWallet}

          walletToEnable={walletToEnable}
          setWalletToEnable={setWalletToEnable}
        />


        <WalletButton 
          onClick={() => Toggle()}
        > 

         {isBusy ?

          <CircularProgress thickness={4} style={true ? {zIndex:'4', color: "#53c8ee"} : {zIndex:'4', color: "#53c8ee", width:'25px', height:'25px'}} />

          : activeWallet !== '' && activeWallet !== undefined && walletLogos.hasOwnProperty(activeWallet) ?

            <DisplayAccount 
              lovelace={lovelace}
              tokens={cur_tokens}

              activeWallet={activeWallet}
              walletLogos={walletLogos}

              snayke_balance={snayke_balance}
              set_snayke_balance={set_snayke_balance}
            />
            // <div>
            //   <img src={root_url + walletLogos[activeWallet]} style={{width:'25px', height:'auto', padding: '15px'}}/>{lovelace} ADA
            // </div>
          :
            <div>
              CONNECT
            </div>

          }

        </WalletButton>

      </ConnectContainer>

    </>

  );
}

export default Connect;
