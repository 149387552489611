import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaClock, FaCheckCircle } from "react-icons/fa";

import Footer from './Footer'

const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: radial-gradient(circle at top, #1e1e42, #0f172a, #050c1e);
  color: #ffffff;
  padding: 20px;
  text-align: center;
  padding-top: 150px;
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 1s ease-in-out;
  
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

const StatusContainer = styled.div`
  background: rgba(30, 41, 59, 0.9);
  padding: 40px;
  border-radius: 15px;
  text-align: center;
  margin: 20px 0;
  width: 500px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const QueuePosition = styled.h2`
  font-size: 2rem;
  color: #ffcc00;
  margin: 10px 0;
  text-shadow: 0px 2px 10px rgba(255, 204, 0, 0.7);
`;

const ETA = styled.h3`
  font-size: 1.5rem;
  color: #60a5fa;
  margin: 10px 0;
`;

const StatusMessage = styled.p`
  font-size: 1.2rem;
  opacity: 0.85;
`;

const TrackOrder = (props) => {
  const [queuePosition, setQueuePosition] = useState('to be updated');
  const [eta, setEta] = useState("to be updated");

  const {stake_address} = props


  async function checkETA(stake_address){

    let query_url = 'https://claymarkets.com/api/custom-clay/eta/' + stake_address + '/'

    let eta_json = await fetch(query_url).then(response => response.json())
                                                                    .then(data => data)

    try{
       //console.log(sale_over)
      let temp_queue = eta_json.queue
      let temp_eta = eta_json.eta

      //console.log(temp_sale_over)

      setQueuePosition(temp_queue)
      setEta(temp_eta)

      return eta_json

    }catch{

      return {}

    }
 

  }


  useEffect(()=>{
    
    checkETA(stake_address);
    const interval = setInterval(() => {
      checkETA(stake_address);
    }, 15000);

    return () => clearInterval(interval);

  },[stake_address])


  return (
    <GlobalContainer>
      <h1>Track Your Order</h1>
      <StatusContainer>
        <FaClock size={50} color="#ffcc00" />
        <QueuePosition>Position in the Queue: {queuePosition}</QueuePosition>
        <ETA>Estimated Time: {eta}</ETA>
        <StatusMessage>Your custom clay NFT is being processed. Please be patient as we craft it!</StatusMessage>
      </StatusContainer>


      <div style={{position:'relative', marginTop:'60px', marginBottom:'150px', width:'100%', 
                                 height:'200px', backgroundColor:'', display:'flex',
                                 justifyContent:'center', alignItems:'center', marginLeft:'0px'
                                }}>
        <div style={{width:'65%', minWidth:'310px', marginTop:'20px'}}>
          <Footer />
        </div>
      </div>


    </GlobalContainer>
  );
};

export default TrackOrder;