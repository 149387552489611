import React, { useState } from "react";
import styled from "styled-components";
import { FaCloudUploadAlt } from "react-icons/fa";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Footer from './Footer'

const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: radial-gradient(circle at top, #1e1e42, #0f172a, #050c1e);
  color: #ffffff;
  padding: 20px;
  text-align: center;
  padding-top: 150px;
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 1s ease-in-out;
  
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

const UploadContainer = styled.div`
  background: rgba(30, 41, 59, 0.9);
  padding: 40px;
  border-radius: 15px;
  text-align: center;
  margin: 20px 0;
  width: 500px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed #7b61ff;
  &:hover {
    background: rgba(50, 60, 85, 0.9);
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  background: #334155;
  color: #ffffff;
  text-align: center;
`;

const Icon = styled(FaCloudUploadAlt)`
  font-size: 3rem;
  color: #7b61ff;
  margin-bottom: 15px;
`;

const SubmitButton = styled.button`
  background: linear-gradient(45deg, #2563eb, #9333ea);
  color: #fff;
  font-size: 1.5rem;
  font-weight: 800;
  padding: 15px 50px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 5px 25px rgba(59, 130, 246, 0.5);
  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 7px 35px rgba(59, 130, 246, 0.7);
  }
  margin-top: 20px;
`;




const PlaceOrder = (props) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");

  const [file_url, set_file_url] = useState('')

  const [uploadSuccess, setUploadSuccess] = useState(false)

  const [trait, setTrait] = useState("");

  const setCurrentStep = props.setCurrentStep

  const stake_address = props.stake_address

  const MAX_FILE_SIZE = 10 * 1024 * 1024
  const ALLOWED_TYPES = ["image/jpeg", "image/png", "image/gif", "image/webp"]

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // Check file type
    if (!ALLOWED_TYPES.includes(selectedFile.type)) {
      notifyError("Invalid file type. Only JPEG, PNG, GIF, and WebP are allowed.");
      return;
    }


    // Check file size
    if (selectedFile.size > MAX_FILE_SIZE) {
      notifyError("File size exceeds 10MB. Please choose a smaller file.");
      return;
    }


    setFile(selectedFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
  };

  const handleSubmit = () => {
    if (!file || !trait) {
      //alert("Please upload an image and enter a trait!");
      notifyError('Please upload an image and enter a trait!')
      return;
    }
    //alert("Order placed successfully!");

    handleUpload()

    if (uploadSuccess){
      notifySuccess('Order placed successfully!')
      setCurrentStep('TrackOrder')
    }


  };




  const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)



  async function postOrderInfo(stake_address, file_url, trait){
    const data = {'stake_address':stake_address,'file_url':file_url, 'trait': trait}
    const response = await fetch('https://claymarkets.com/api/custom-clay/place-order/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })


    return response.json()
}




  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a file first.");
      return;
    }

    setUploading(true);
    setMessage("");

    try {
      // Step 1: Get pre-signed URL from Django backend
      const response = await fetch(
        `https://claymarkets.com/api/custom-clay/get-presigned-url/?fileName=${file.name}&fileType=${file.type}&stake_address=${stake_address}`
      );
      const data = await response.json();

      //console.log(data)

      if (!data.upload_url) {
        throw new Error("Failed to get pre-signed URL");
      }

      console.log(stake_address)
      console.log(data.file_url)
      console.log(trait)

      // let post_response = await postOrderInfo(stake_address, String(data.file_url), trait)
      // console.log(post_response)

      // Step 2: Upload file to DigitalOcean Spaces
      const uploadResponse = await fetch(data.upload_url, {
        method: "PUT",
        headers: {
          "Content-Type": file.type
        },
        body: file
      });

      if (!uploadResponse.ok) {
        throw new Error("Upload failed");
      }

      //setMessage(`Upload successful! File URL: ${data.file_url}`);

      notifySuccess(`Upload successful! File URL: ${data.file_url}`)

      setUploadSuccess(true)

      //set_file_url(data.file_url)

      let post_response = await postOrderInfo(stake_address, String(data.file_url), trait)
      console.log(post_response)


    } catch (error) {
      //console.error(error);
      //setMessage("Upload failed.");
      notifyError("Upload failed")
    } finally {
      setUploading(false);
    }
  };


  return (

    <GlobalContainer>
      <h1>Sumbit Your Custom Clay Order</h1>
      <UploadContainer onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
        <Icon />
        <h3>Upload your Image</h3>
        <h5>Your custom clay will be based on that image</h5>
        <h3>Drag & Drop or Click to Upload</h3>
        <input type="file" id="fileUpload" style={{ display: "none" }} onChange={handleFileChange} />
        <label htmlFor="fileUpload" style={{ cursor: "pointer", color: "#7b61ff", fontSize: "1.2rem" }}>
          Browse Files
        </label>
        {file && <p>{file.name}</p>}
      </UploadContainer>

      <UploadContainer>
        <h3>Enter a Trait</h3>
        <h5>This can be a trait you would like to be featured in your custom clay. <br/> It can be a cowboy hat, a frog or any other trait you can think of. <br/> Please provide a description.</h5>
        <Input
          type="text"
          placeholder="Enter a trait for your custom clay..."
          value={trait}
          onChange={(e) => setTrait(e.target.value)}
        />
      </UploadContainer>

      {!uploading ?
        <SubmitButton 
        onClick={() => {
          handleSubmit()
        }}>Place Order</SubmitButton>
      : <h3>Uploading...</h3>}



      <div style={{position:'relative', marginTop:'60px', width:'100%', 
                                 height:'200px', backgroundColor:'', display:'flex',
                                 justifyContent:'center', alignItems:'center', marginLeft:'0px', marginBottom: '50px'
                                }}>
        <div style={{width:'65%', minWidth:'310px', marginTop:'20px'}}>
          <Footer />
        </div>
      </div>


    </GlobalContainer>

  );
};

export default PlaceOrder;