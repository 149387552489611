import {useState, useEffect} from 'react'
import React from "react";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";

import Footer from './Footer'

const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: radial-gradient(circle at top, #1e1e42, #0f172a, #050c1e);
  color: #ffffff;
  padding: 20px;
  text-align: center;
  padding-top: 150px;
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 1s ease-in-out;
  
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(30, 41, 59, 0.9);
  padding: 40px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  max-width: 800px;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  background: #1e1e42;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 30px;
`;

const NFTImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  text-align: left;
`;

const SuccessIcon = styled(FaCheckCircle)`
  font-size: 3rem;
  color: #00ff7f;
  margin-bottom: 15px;
`;

const OrderComplete = (props) => {

  const {stake_address} = props

  const [imageUrl, setImageUrl] = useState('')



  async function checkFinal(stake_address){

    let query_url = 'https://claymarkets.com/api/custom-clay/finished-clay/' + stake_address + '/'

    let eta_json = await fetch(query_url).then(response => response.json())
                                                                    .then(data => data)

    try{
       //console.log(sale_over)
      let temp_imageUrl = eta_json.image
      

      setImageUrl(temp_imageUrl)

      return eta_json

    }catch{

      return {}

    }
 

  }



  useEffect(()=>{
    
    checkFinal(stake_address);
    const interval = setInterval(() => {
      checkFinal(stake_address);
    }, 15000);

    return () => clearInterval(interval);

  },[stake_address])


  return (
    <GlobalContainer>
      <ContentContainer>
        <ImageContainer>
          <NFTImage src={imageUrl} alt="Final NFT" />
        </ImageContainer>
        <InfoContainer>
          <SuccessIcon />
          <h2>Order Complete</h2>
          <p>Your custom NFT has been successfully minted and sent to your wallet.</p>
          <p>Check your wallet to view your unique Clay NFT!</p>
        </InfoContainer>
      </ContentContainer>

      <div style={{position:'relative', marginTop:'60px', marginBottom:'150px', width:'100%', 
                                 height:'200px', backgroundColor:'', display:'flex',
                                 justifyContent:'center', alignItems:'center', marginLeft:'0px'
                                }}>
        <div style={{width:'65%', minWidth:'310px', marginTop:'20px'}}>
          <Footer />
        </div>
      </div>

    </GlobalContainer>
  );
};

export default OrderComplete;