import {useReducer, useState, createContext, useEffect} from 'react'

import styled from 'styled-components';

// import Connect from './Connect'


import CollectionsToggle from './CollectionsToggle'

import AppBar from '@mui/material/AppBar';

import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import AlertPopUp from './AlertPopUp'
import Wallet from './Wallet'
import Loading from './Loading'


import './SideBar.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useMediaQuery from '@mui/material/useMediaQuery';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import { useTheme } from '@mui/material/styles';


import CircularProgress from '@mui/material/CircularProgress';


import AccountSummary from './AccountSummary'

const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});


  const ClaimButton = styled.button`
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border:0px;    
    cursor: pointer;
    
  :before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 140%;
    background: linear-gradient(-180deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }

  @keyframes animate 
  {
    0%
    {
      transform: rotate(0deg);
    }
    100%
    {
      transform: rotate(360deg);
    }
  }
  :after
  {
    content: '';
    position: absolute;
    inset : 2px;
    background: linear-gradient(30deg, rgba(47,53,85,1) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
    z-index: 1;
    border:0px;
  }

  :hover
  {
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
  }


  :hover:before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 250%;
    background: linear-gradient(120deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }
  `

  const ButtonText = styled.h1`
    position: relative;
    font-size: 1.8em;
    font-family: 'shortstack';
    letter-spacing: 0.1em;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    color: #fff;
    z-index: 4;
  `


const initialState = {
    activeWallet: '',
    address: '',
    stake_address: '',
    lovelace: null,
    tokens: [],
    selectedTokens: [],
    isVoteOpen: false,
    voteOption: null,
    voteProposal: null,
}




const actions = {
    SET_ACTIVE_WALLET: "SET_ACTIVE_WALLET",
    SET_ADDRESS: "SET_ADDRESS",
    SET_STAKE_ADDRESS: "SET_STAKE_ADDRESS",
    SET_LOVELACE: "SET_LOVELACE",
    SET_TOKENS: "SET_TOKENS",
    SET_SELECTED_TOKENS: "SET_SELECTED_TOKENS",
    SET_IS_VOTE_OPEN: "SET_IS_VOTE_OPEN",
    SET_VOTE_OPTION: "SET_VOTE_OPTION",
    SET_VOTE_PROPOSAL: "SET_VOTE_PROPOSAL",
    RESET: "RESET"
}



function reducer(state, action){
    switch (action.type){
        case actions.SET_ACTIVE_WALLET:
            return {...state, activeWallet: action.value};        
        case actions.SET_ADDRESS:
            return {...state, address: action.value};
        case actions.SET_STAKE_ADDRESS:
            return {...state, stake_address: action.value};
        case actions.SET_LOVELACE:
            return {...state, lovelace: action.value};
        case actions.SET_TOKENS:
            return {...state, tokens: action.value};
        case actions.SET_SELECTED_TOKENS:
            return {...state, selectedTokens: action.value};
        case actions.SET_IS_VOTE_OPEN:
            return {...state, isVoteOpen: action.value};
        case actions.SET_VOTE_OPTION:
            return {...state, voteOption: action.value};
        case actions.SET_VOTE_PROPOSAL:
            return {...state, voteProposal: action.value};
        case actions.RESET:
            return {...state, ...initialState}
        default:
            return state;
    }
}

export const MainClaimContext = createContext()

function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        activeWallet: state.activeWallet,
        address: state.address,
        stake_address: state.stake_address,
        lovelace: state.lovelace,
        tokens: state.tokens,
        selectedTokens: state.selectedTokens,
        isVoteOpen: state.isVoteOpen,
        voteOption: state.voteOption,
        voteProposal: state.voteProposal,
        
        setActiveWallet: value => {
            dispatch({type: actions.SET_ACTIVE_WALLET, value})
        },
        setAddress: value => {
            dispatch({type: actions.SET_ADDRESS, value})
        },
        setStakeAddress: value => {
            dispatch({type: actions.SET_STAKE_ADDRESS, value})
        },
        setLovelace: value => {
            dispatch({type: actions.SET_LOVELACE, value})
        },
        setTokens: value => {
            dispatch({type: actions.SET_TOKENS, value})
        },
        setSelectedTokens: value => {
            dispatch({type: actions.SET_SELECTED_TOKENS, value})
        },
        setIsVoteOpen: value => {
            dispatch({type: actions.SET_IS_VOTE_OPEN, value})
        },
        setVoteOption: value => {
            dispatch({type: actions.SET_VOTE_OPTION, value})
        },
        setVoteProposal: value => {
            dispatch({type: actions.SET_VOTE_PROPOSAL, value})
        },
        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainClaimContext.Provider value={value}>
            {children}
        </MainClaimContext.Provider>
        )
}


export default function WalletContext(){
    
    const [assetsOwned, setAssetsOwned] = useState()
    const [isWalletLoading, setIsWalletLoading] = useState(false)

    const [walletName, setWalletName] = useState()
    const [isOpen, setIsOpen] = useState()


    const [isWalletOpen, setIsWalletOpen] = useState(false)
    const [isDiscord, setIsDiscord] = useState(false)

    const [hasAssets, setHasAssets] = useState(false)

    const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
    const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesM = useMediaQuery(theme.breakpoints.up('md'));
    const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));


    const [authToken, setAuthToken] = useState('')

    const [includeCn,setIncludeCn] = useState(true)
    const [includeGc,setIncludeGc] = useState(true)

    const [totalCn, setTotalCn] = useState(0)
    const [totalGc, setTotalGc] = useState(0)


 
    return(
       <>

        <div style={{position:'relative',overflow:'auto',height:'100vh', display:'flex',justifyContent:'center', alignItems:'center'}}>
             <div style={{position:'relative' ,width:'100vw', display:'flex', justifyContent:'center'}}>
                    
{/*                    <div style={{position:'relative', height:isWalletOpen ?'90vh':'50vh'}}>

                    {matchesM ?
                        <div>
                            <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayNationLogo_Final.pdf%20(1).png" style={{"width":"30%","height":"auto", 'minWidth':"250px"}} />
                        </div>
                    :

                    <div>

                        <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayNationLogo_Final.pdf%20(1).png" style={{"width":"100%","height":"auto", minWidth:'250px', 'maxWidth':"400px"}} />

                    </div>

                    }

                    {isWalletLoading ?

                        <div style={{marginTop:'25px'}}>
                            <CircularProgress style={{'color': 'rgb(242, 138, 128)'}} />
                        </div>
                    :

                    

                    <div style={{marginTop:'25px'}}>
                      <AccountSummary  setIsWalletOpen={setIsWalletOpen} setIsDiscord={setIsDiscord} />
                    </div>


                 }


                 
                     <div style={{position:'relative', display:'flex', justifyContent:'center', marginTop:'60px'}}>


                     

                     <div>
                      <button id="claimButton" className="claimBtn" onClick={(event)=>{

                        setIsOpen(true)

                        }}>
                          <ButtonText>Connect</ButtonText>
                      </button>
                    </div>


                    </div>


                  {isWalletOpen ?
                   <div style={{position:'relative', display:'flex',justifyContent:'center', alignItems:'center', width:'100%', marginTop:'60px'}}>

                    <Paper sx={{position:'relative', display:'flex', alignItems:'center',width:'365px', height:'200px',
                      borderRadius: '74px',
                      backdropFilter: 'blur(85px)',
                      backgroundImage: `linear-gradient(180deg, rgba(69, 62, 112, 0.95) 0%, rgba(29, 58, 61, 1) 100%)`
                    }}>



                    <CollectionsToggle includeCn={includeCn} setIncludeCn={setIncludeCn} includeGc={includeGc} setIncludeGc={setIncludeGc} totalCn={totalCn} totalGc={totalGc} />
                    
                    </Paper>

                     <img src={"/gradient_border.svg"} style={{width:'390px',marginLeft:'-378px'}}/>

                    </div>

                    :

                    null}





                    <div style={{position:'relative', display:'flex',width:'100%', justifyContent:'center', marginTop:'30px'}}>

                    <div style={{position:'relative', width:'100%',height:'20vh'}}>

                        {isWalletLoading ?

                        <Loading /> 

                        :

                        <Wallet isWalletOpen={isWalletOpen} setHasAssets={setHasAssets} authToken={authToken} setAuthToken={setAuthToken} includeCn={includeCn} includeGc={includeGc} setTotalCn={setTotalCn} setTotalGc={setTotalGc} />
                    }

                    </div>
                    </div>

                    </div>*/}





                <div style={{position:'relative', height:isWalletOpen ?'90vh':'90vh'}}>


                    {matchesM ?
                        <div>
                            <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayNationLogo_Final.pdf%20(1).png" style={{"width":"30%","height":"auto", 'minWidth':"250px"}} />
                        </div>
                    :

                    <div>

                        <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayNationLogo_Final.pdf%20(1).png" style={{"width":"100%","height":"auto", minWidth:'250px', 'maxWidth':"400px"}} />

                    </div>

                    }


                    <div style={{position:'relative', display:'flex',width:'100%', justifyContent:'center', marginTop:'30px'}}>

                        <div style={{position:'relative', width:'100%',height:'20vh'}}>

                            

                            <Wallet isWalletOpen={isWalletOpen} setHasAssets={setHasAssets} authToken={authToken} setAuthToken={setAuthToken} includeCn={includeCn} includeGc={includeGc} setTotalCn={setTotalCn} setTotalGc={setTotalGc} />
                        

                        </div>
                    </div>

                </div>
                


                </div>
            </div>

               

            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque" }} />

          
            {/*<AlertPopUp isOpen={isOpen} setIsOpen={setIsOpen} setIsWalletLoading={setIsWalletLoading} setAuthToken={setAuthToken} />*/}
          

         </>

        )
}