import {useState, useEffect, useContext} from 'react'

import styled from 'styled-components';

import Connect from './Connect'

import Footer from './Footer'

import { Link } from 'react-router-dom';


import {MainClaimContext} from 'components/ClaimContext'

import ConnectPopUp from './ConnectPopUp'

import {Provider} from 'components/ClaimContext'

import Cards from './Cards'

import AlertPopUp from './AlertPopUp'

import Carousel from './Carousel'

import { Grid } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createGlobalStyle } from "styled-components";


import Cart from './Cart/Cart'

import ClayPayScene from './ClayPay/ClayPayScene'



import Snayke_Vote_2 from './Snayke_Swap_2'





import PreSale from './Custom_Clays_presale'

import TransactionProcessing from './Custom_Clays_Transaction_Processing'

import PaymentSuccess from './Custom_Clays_Payment_success'

import PlaceOrder from './Custom_Clays_place_order'

import TrackOrder from './Custom_Clays_track_order'

import OrderComplete from './Custom_Clays_order_complete'


const Title = styled.div`
  color: rgba(255, 255, 255, 0.93);
  font-family: 'ShortStack';
  font-size: 44px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);


  @media screen and (max-width: 680px) {
    font-size: 34px; 
  }
   
`;

const Subtitle = styled.div`
  color: #adffd9;
  font-family: 'ShortStack';
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  word-wrap: break-word;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(174, 245, 184, 0.25);

  @media screen and (max-width: 680px) {
    font-size: 30px; 
  }

`;


const NavBar = styled.div`
    position: absolute;
    top: 10px;
    right: 0px; 
    z-index: 1001;
    width: 100%; 
    height: 100px; 
    display: flex; 
    justify-content: center;
    align-items: center;
`


const NavBarContents = styled.div`
    width: 90%; 
    height: 90%; 
    display: flex; 
    justify-content: space-between;
    align-items: center;
    border-radius: 30px; 
    margin-top: 20px;
    background: transparent;
    transition: ease-in-out all 0.3s;  
`


// const Container = styled.div`
//   width: 100%; 
//   height: 100%;
//   background-image: url('https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ls_sonicvillage_land.png');
//   background-size: cover;
//   background-repeat: no-repeat;

//   overflow: hidden;
// `;


const ClaimButton = styled.button`
        background-color: #ED1A70;
        
        
        width: 90px; 
        height: 50px;
        border-radius: 13px;
        outline: 2px solid #ED1A70;    
        border: none;

        margin-top: 20px;

        margin-left: 25%;
        
        font-family: ClayNationFont;
        
        text-align: center;
        color:  white;
        padding-top: 5px;
       
        cursor: pointer;
        
        z-index: 10;
        letter-spacing: 0.1rem;

        &:hover {
          background-color: #ED1A70;
          box-shadow: 0px 0px 20px #ED1A70;
        }


        @media screen and (max-width: 680px) {
          max-width: 125px;
          max-height: 55px; 
          font-size: 1.3rem;
        }
        @media screen and (min-width: 680px) {
          max-width: 600px;
          max-height: 65px;
          font-size: 1.5rem;
        }
`



const Container = styled.div`


background: radial-gradient(circle at top, #1e1e42, #0f172a, #050c1e);
background-blend-mode: overlay, normal;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
height: 100vh;
height: 100svh;


width: 100%; 
height: 100%;
overflow: hidden;

overflow-y: hidden;


`


const Backdrop = styled.div`
    background-color: rgba(10,10,10, 0.2);
    backdrop-filter: blur(15px);
`


const CNLogoContainer = styled.div`
    width:'200px', 
    height:'90%', 
    backgroundColor:'', 
    marginLeft:'35px', 
    display:'flex', 
    alignItems:'center'
`


const CNLogo = styled.img`

    height: auto;   

    @media screen and (max-width: 680px) {
        width: 180px; 
    }
    @media screen and (min-width: 680px) {
        width: 280px; 
    }
    
` 


const TreeImage = styled.img`

    height: auto;   

    @media screen and (max-width: 680px) {
        width: 180px; 
    }
    @media screen and (min-width: 680px) {
        width: 280px; 
    }
    
`


const NavBarUpdated = styled.div`
  width: 90%;
  height: 12%;
  background:  var(--background);
  background-blend-mode: overlay;
  position: fixed;
  top: 7%;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
`



function HandlesPreProd(){


   
    const [isWalletLoading, setIsWalletLoading] = useState(false)
    const [mintAsset, setMintAsset] = useState({})
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [submittedTx, setSubmittedTx] = useState('')


    const [buttonPopup, setButtonPopup] = useState(false);


    const [claimCode, setClaimCode] = useState('')

    const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Do something with the input value, like sending it to a server or processing it
    //console.log('Submitted value:', inputValue);
    // Optionally, clear the input field after submission
    setClaimCode(inputValue)
    //setCartItems(['Baked_Nation_Hoodie'])
    //setInputValue('');
    //setClayPayOpen(true)


  };


  useEffect(() => {

    if (claimCode.toUpperCase() == 'GOOD CHARLOTTE'){
        setIsRiddleSolved(true)
    }

  },[claimCode])





    const GlobalStyle = createGlobalStyle`
        :root {
            --background:  rgba(28, 37, 52, 0.5);

             --background-hover:  rgba(28, 37, 52, 0.9);
              --clayblue-primary: #1E84FC;
              --clayblue-secondary:  #5d96ffcd;
              --claymint-primary: #a0fac8;

              font-family: ButtonFont;

        }
        `
const [isPopUpOpen, setIsPopUpOpen] = useState(false)

const [isRiddleSolved, setIsRiddleSolved] = useState(true)

const [activeWallet, setActiveWallet] = useState('')

const [stake_address, set_stake_address] = useState('')

const [snayke_balance, set_snayke_balance] = useState(0)

const [isBusy, setIsBusy] = useState(false)
const [updateWallet, setUpdateWallet] = useState(false)
const [walletToEnable, setWalletToEnable] = useState('')


const [cartItems, setCartItems] = useState([])

const [clayPayOpen, setClayPayOpen] = useState(false)



const theme = useTheme();

const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
const matchesM = useMediaQuery(theme.breakpoints.up('md'));
const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));


const [matchingPairs, setMatchingPairs] = useState()


const [saleOver, setSaleOver] = useState(false)





const [currentStep, setCurrentStep] = useState('TransactionProcessing')











function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}





return(

    <Container>
     <Provider>

        <GlobalStyle />

        <Backdrop>

        
        <NavBar>

          <NavBarContents>

            <Link to="/">
              <CNLogoContainer>
                  <CNLogo src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayNationLogo_Final.pdf%20(1).png" />
              </CNLogoContainer>
            </Link>

            <div style={{marginRight:'35px'}}>
                
                <Connect 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    isBusy={isBusy}
                    setIsBusy={setIsBusy}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    set_stake_address={set_stake_address}

                    snayke_balance={snayke_balance}
                    set_snayke_balance={set_snayke_balance}
                />


            </div>

         </NavBarContents>

        </NavBar>



       
        <>


         {isPopUpOpen ?
        

           //  <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', 
           //     height:'100vh', backgroundColor:'', display:'flex', 
           //     justifyContent:'center', alignItems:'center', zIndex: '2000', overflow:'scroll'
           // }}>


             <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', 
               height:'100vh', backgroundColor:'', display:'flex', 
               justifyContent:'center', alignItems:'center', zIndex: '2000', overflow:'scroll'
           }}>
            
            
               <ConnectPopUp 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    activeWallet={activeWallet}
                    setActiveWallet={setActiveWallet}


                    isBusy={isBusy} 
                    setIsBusy={setIsBusy}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}
                />

           </div>
                  

       : null}


      

        <Snayke_Vote_2 matchesM={matchesM} isPopUpOpen={isPopUpOpen} stake_address={stake_address} snayke_balance={snayke_balance} set_snayke_balance={set_snayke_balance} />

        


        </>






  

       </Backdrop>


       <div style={{textAlign:'center'}}>
        <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
       </div>

       <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque", fontFamily:'ShortStack' }} />


     </Provider>
    </Container>
)
}
export default HandlesPreProd;