
import { useState, useContext, useEffect } from 'react';
//import {MainClaimContext} from './WalletContext'

import styled from "styled-components";

import {Buffer} from 'buffer'

import './StakingSideBar.css'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Card from './Card'
import EmptyWallet from './EmptyWallet'

import cn_meta from '../../cn_info.json'
import gc_meta from '../../gc_info.json'



const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  background: rgba(67, 90, 113, 1);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  backdrop-filter: blur(71px);
  border-radius: 17px;
  transition: all 0.5s ease;
  overflow: hidden;
  
  &:hover::before, &.pressed::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 80%);
    opacity: 0.7;
    border-radius: 17px;
    transition: all 0.5s ease;
  }
  
  &:hover::after {
    content: "";
    position: absolute;
    inset: 2px;
    background: rgba(67, 90, 113, 1);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    border-radius: 17px;
    transition: all 0.5s ease;
  }
  
  &.focused::after, &.pressed::after {
    content: "";
    position: absolute;
    inset: 2px;
    background: rgba(67, 90, 113, 1);
    box-shadow: inset 5px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
    transition: all 0.5s ease;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  text-align: center;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  position: relative;
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;


  


function Wallet(props) {

   const setHasAssets = props.setHasAssets
   const authToken = props.authToken

   const setAuthToken = props.setAuthToken

    const includeCn = props.includeCn

    const includeGc = props.includeGc

    const setTotalCn = props.setTotalCn
    const setTotalGc = props.setTotalGc

    const isWalletOpen = props.isWalletOpen


   //const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)
   const eligible_policies = ['40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728','94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768']

   const clay_info = {'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728':{collection:'cn',base_name:'ClayNation'}, '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768':{collection:'gc',base_name:'ClayCharlotte'}}
  
   const [testTokens, setTestTokens] = useState([])

   const [assets, setAssets] = useState([])


   const [model3D, setModel3D] = useState('')


   const [searchedTokens, setSearchedTokens] = useState([])

   const [text, setText] = useState("");


   const [text2, setText2] = useState("");




   const [isFocused, setIsFocused] = useState(false);
  const [isPressed, setIsPressed] = useState(false);




  const [isFocused2, setIsFocused2] = useState(false);
  const [isPressed2, setIsPressed2] = useState(false);


  useEffect(() => {

    setAuthToken(text2)

  },[text2])

   useEffect(() => {


    if (text.slice(0,2) == 'cn' || text.slice(0,2) == 'CN'){

       setSearchedTokens([
      {
        'collection':'cn',
        'serial': text.slice(2,6)
      }
    ])

    }else if (text.slice(0,2) == 'gc' || text.slice(0,2) == 'GC'){

       setSearchedTokens([
      {
        'collection':'gc',
        'serial': text.slice(2,6)
      }
    ])

    }else{

        setSearchedTokens([
           {
            'collection':'cn',
            'serial': text.slice(0,4)
          },
          {
            'collection':'gc',
            'serial': text.slice(0,4)
          }
        ])

    }

   

   },[text])
   

   useEffect(() => {

      let temp = []

      let cn = []
      let gc = []

      for (let i = 0; i < searchedTokens.length; i++){
        
        let cur_collection = searchedTokens[i]['collection']

        let serial = searchedTokens[i]['serial']

          if (cur_collection == 'cn'){
            
            try{
              let cur_image = cn_meta[serial]['image']
              let cur_background = cn_meta[serial]['background']
              cn.push({collection:'cn', serial:serial, image:cur_image, background: cur_background})
            }catch{

            }
            
            
          }else if (cur_collection == 'gc'){
              
            try{
              let cur_image = gc_meta[serial]['image']
              let cur_background = gc_meta[serial]['background']
              //console.log(cur_background)
              gc.push({collection:'gc', serial:serial, image:cur_image, background: cur_background})
            }catch{

            }
             
          }

      }

      if (includeCn){
        temp = temp.concat(cn)
      }

      if (includeGc){
        temp = temp.concat(gc)
      }

      setTotalCn(cn.length)

      setTotalGc(gc.length)


      if (temp.length > 0){
        setHasAssets(true)
      }else{
        setHasAssets(false)
      }

      setAssets(temp)



   },[searchedTokens])

  return (

    <>


<div style={{display:'flex', flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'center'}}>
  
  <div style={{width:'300px', marginBottom:'20px'}}>

    <SearchContainer 
      className={`${isFocused2 ? "focused" : ""} ${isPressed2 ? "pressed" : ""}`}
    >
      <SearchInput
        type="text"
        placeholder="Auth token"
        onFocus={() => setIsFocused2(true)}
        onBlur={() => setIsFocused2(false)}
        onMouseDown={() => setIsPressed2(true)}
        onMouseUp={() => setIsPressed2(false)}

        value={text2}
        onChange={(e) => setText2(e.target.value)}
      />
    </SearchContainer>

  </div>


    <div style={{width:'300px'}}>
      <SearchContainer 
        className={`${isFocused ? "focused" : ""} ${isPressed ? "pressed" : ""}`}
      >
        <SearchInput
          type="text"
          placeholder="Search..."
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onMouseDown={() => setIsPressed(true)}
          onMouseUp={() => setIsPressed(false)}

          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </SearchContainer>

    </div>

  </div>



     <Grid container justifyContent="center" alignItems="center" style={{marginTop:'30px'}} style={{width:'100%'}}>

          {
            assets && assets.length > 0 ? 

            assets.map((token)=>{
              return(
               
                <Grid item xs={12} sm={12} md={5} lg={4} xl={3} key={token.collection.toLowerCase()+'#'+String(token.serial)} >
                <div style={{position:'relative', display:'flex', justifyContent:'center'}}>
                 <Card serial={token.serial} collection={token.collection} image={token.image} background={token.background} model3D={model3D} setModel3D={setModel3D} authToken={authToken} setAuthToken={setAuthToken} />
                </div>
                </Grid>
                 )
            })
           
            :

            isWalletOpen ?
            
            <EmptyWallet />

            : 

            null
          }


      </Grid>


      </>
  );
}

export default Wallet;