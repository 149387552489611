import React from "react";
import styled from "styled-components";
import { FaSpinner } from "react-icons/fa";

import Footer from './Footer'

const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: radial-gradient(circle at top, #1e1e42, #0f172a, #050c1e);
  color: #ffffff;
  padding: 20px;
  text-align: center;
  padding-top: 120px;
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 1s ease-in-out;
  
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(30, 41, 59, 0.9);
  padding: 40px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  max-width: 600px;
`;

const LoadingIcon = styled(FaSpinner)`
  font-size: 3rem;
  color: #ffcc00;
  margin-bottom: 15px;
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const TransactionProcessing = () => {
  return (
    <GlobalContainer>
      <ContentContainer>
        <LoadingIcon />
        <h2>Transaction Processing</h2>
        <p>Your payment transaction is being processed.</p>
        <p>Status will be updated once it is confirmed on-chain.</p>
      </ContentContainer>

      <div style={{position:'relative', marginTop:'60px', marginBottom:'50px', width:'100%', 
                                 height:'200px', backgroundColor:'', display:'flex',
                                 justifyContent:'center', alignItems:'center', marginLeft:'0px'
                                }}>
        <div style={{width:'65%', minWidth:'310px', marginTop:'20px'}}>
          <Footer />
        </div>
      </div>

    </GlobalContainer>
  );
};

export default TransactionProcessing;
